import React from "react"
import PropTypes from "prop-types"
import { MapPinIcon } from "@heroicons/react/20/solid"
import CrowsHead from "../images/crowshead.png"

function Fixture({ fixture }) {


  return (
    <div className="text-gray-600">
      <div className="flex flex-col w-full pb-10  border-b border-gray-100">
        <p className="text-sm flex text-gray-900 justify-center">
          <time dateTime={new Date(`${fixture.day} ${fixture.month} 2023`)}>
            {fixture.day} {fixture.date} {fixture.month} 2023
          </time>
        </p>
        <p className="flex text-sm text-gray-500 justify-center">
          <MapPinIcon
            className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-600"
            aria-hidden="true"
          />
          <span className="truncate">{fixture.venue}</span>
        </p>
      </div>


      {/* fixture */}
      {fixture.opponents.map(opponent => (
        <div className="flex flex-col" key={opponent.name}>
          <div className="py-8 md:py-0 md:h-20 md:pt-5">
              {opponent.home === false ? (
                
                <div className="grid md:grid-cols-fixtures place-items-center ">
                  <img
                    className="h-12 w-12 "
                    src={CrowsHead}
                    alt=""
                  />
                  <p className="text-center pb-4 md:pb-0">Manchester Crows </p>
                  <p className="text-center pb-4 md:pb-0">@</p>
                  <p className="text-center">{opponent.name}</p>
                  <img
                    className="h-12 w-12 "
                    src={opponent.imageUrl}
                    alt=""
                  />
                </div>
              ) : (
                <div className="grid md:grid-cols-fixtures place-items-center">
                  <img
                    className="h-12 w-12 "
                    src={opponent.imageUrl}
                    alt=""
                  />
                  <p className="text-center pb-4 md:pb-0">{opponent.name}</p>
                  <p className="text-center pb-4 md:pb-0">@</p>
                  <p className="text-center">Manchester Crows</p>
                  <img
                    className="h-12 w-12 "
                    src={CrowsHead}
                    alt=""
                  />
                </div>
              )}
          </div>
          <div className="relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-100" />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

Fixture.propTypes = {
  fixture: PropTypes.object,
}

Fixture.defaultProps = {
  fixture: {},
}

export default Fixture
