import React from "react"

import Oilcats from "../images/Opponents/AberdeenOilcats.jpg"
import Titans from "../images/Opponents/tits.jpg"
import Comets from "../images/Opponents/ClydeComets.jpg"
import Blackhawks from "../images/Opponents/NewcastleBlackhawks.jpg"
import Kings from "../images/Opponents/DunfermerlineKings.png"
import Scorpions from "../images/Opponents/SalfordScorpions.jpg"
import Outlaws from "../images/Opponents/EdinburghOutlaws.jpg"
import Broncos from "../images/Opponents/GrangemouthBroncos.jpg"
import Bandits from "../images/Opponents/WiganBandits.jpg"
import Hornets from "../images/Opponents/GlasgowHornets.jpg"

import Fixture from "./fixture"
import FixturePagination from "./fixturepagination"

const fixtures = [
  {
    id: 1,
    week: 1,
    day: "Saturday",
    date: 15,
    month: "April",
    venue: "Wigan",
    opponents: [
      {
        name: "Newcastle Blackhawks",
        home: true,
        imageUrl: Blackhawks,
      },
      {
        name: "Salford Scorpions",
        home: false,
        imageUrl: Scorpions,
      },
    ],
  },
  {
    id: 2,
    week: 2,
    day: "Sunday",
    date: 7,
    month: "May",
    venue: "Salford",
    opponents: [
      {
        name: "Salford Scorpions",
        home: true,
        imageUrl: Scorpions,
      },
      {
        name: "Manchester Titans",
        home: false,
        imageUrl: Titans,
      },
    ],
  },
  {
    id: 3,
    week: 3,
    day: "Saturday",
    date: 27,
    month: "May",
    venue: "Glasgow",
    opponents: [
      {
        name: "Grangemouth Broncos",
        home: false,
        imageUrl: Broncos,
      },
      {
        name: "Clyde Comets",
        home: true,
        imageUrl: Comets,
      },
      {
        name: "Glasgow Hornets",
        home: false,
        imageUrl: Hornets,
      },
    ],
  },
  {
    id: 4,
    week: 4,
    day: "Saturday",
    date: 24,
    month: "June",
    venue: "Manchester",
    opponents: [
      {
        name: "Manchester Titans",
        home: true,
        imageUrl: Titans,
      },
      {
        name: "Wigan Bandits",
        home: true,
        imageUrl: Bandits,
      },
    ],
  },
  {
    id: 5,
    week: 5,
    day: "Saturday",
    date: 22,
    month: "July",
    venue: "Manchester",
    opponents: [
      {
        name: "Edinburgh Outlaws",
        home: false,
        imageUrl: Outlaws,
      },
      {
        name: "Aberdeen Oilcats",
        home: false,
        imageUrl: Oilcats,
      },
      {
        name: "Dunfermline Kings",
        home: false,
        imageUrl: Kings,
      },
    ],
  },
  {
    id: 6,
    week: 6,
    day: "Saturday",
    date: 12,
    month: "August",
    venue: "Newcastle",
    opponents: [
      {
        name: "Wigan Bandits",
        home: false,
        imageUrl: Bandits,
      },
      {
        name: "Newcastle Blackhawks",
        home: false,
        imageUrl: Blackhawks,
      },
    ],
  },
]



export const Fixtures = () => {
const [selectedFixture, setSelectedFixture] = React.useState(0)
  return (
    <div>

      <div className="my-8 w-full md:w-2/3 mx-auto ">
        <div className="overflow-hidden bg-white shadow sm:rounded-md py-8 px-4  sm:px-10">


          {fixtures.map((fixture, i) => (
            <div key={i} className={i === selectedFixture ? "block" : "hidden"}>
                <Fixture fixture={fixture} />
            </div>
          ))}
           <FixturePagination fixtures={fixtures} setSelectedFixture={setSelectedFixture} selectedFixture={selectedFixture}/>
      </div>
    </div>
    </div>
  )
}
