import * as React from "react"
import YouTube from "react-youtube"

const About = () => {
  return (
    <div className="overflow-hidden bg-grey-50 py-4">
      <div className="bg-white py-8 shadow sm:rounded-lg sm:px-10 w-full md:w-2/3 mx-auto space-y-8 px-6 lg:px-8">
        <div className="relative z-10 mx-auto lg:mx-0 lg:max-w-5xl lg:pr-10">
          <p className="">
            Flag Football is an exciting and fast-paced version of American
            Football that's rapidly gaining popularity across the UK. It's a
            non-contact sport where tackles are made by pulling a flag from a
            player's hips, which welcomes athletes of all abilities, genders,
            and levels of experience.
          </p>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-8">
          <div className="relative z-10">
            <div className=" mx-auto  lg:max-w-none">
              <p className="pb-8">
                The Manchester Crows are one of the most well-known and
                respected flag teams in the country. Founded in 2015, we play in
                the Northern Premiership and have qualified for the National
                Playoffs every season since our second year.
              </p>

              <p>
                The sport is governed in the UK by the British American Football
                Association (BAFA), and works in conjunction with the National
                Football League UK Flag Football (NFL UK) and the International
                Federation of American Football (IFAF).
              </p>
            </div>
          </div>
          <div className="relative mx-auto mt-12  text-base lg:mt-0 lg:max-w-none">
            <svg
              className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"
              />
            </svg>
            <blockquote className="relative hidden md:block rounded-lg bg-white shadow-lg">
              <div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
                <YouTube videoId="TC5Wz_PXS1E" />
              </div>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
