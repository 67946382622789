import React from "react"
import TeamPhoto from "../images/teamphoto.png"
import crowshead from "../images/crowshead.png"
import Linktree from "../images/linktree-logo.svg"

const Welcome = () => {
  return (
    // <div className="prose w-full md:w-2/3 bg-white  mx-auto py-8 px-4 shadow sm:rounded-lg sm:px-10">
    //     <p className="text-3xl font-sans">Welcome to the Manchester Crows!</p>
    //   Here you'll find information on the team,
    //   our fixtures, and how to get in touch with us. Make sure to follow our
    //   <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://linktr.ee/manchestercrows"> social media accounts </a> for all of our
    //   updates!
    //   <img src={TeamPhoto} alt="Manchester Crows Team Photo" />
    // </div>
    <div className="bg-grey-50">
      <div className="relative isolate overflow-hidden">
        <div className="mx-auto max-w-7xl pt-10 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:py-40 lg:px-8">
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg prose prose-a:no-underline">
                
                <h1 className="mt-10 text-4xl font-bold tracking-tight text-crows-red sm:text-6xl">
                  Welcome
                </h1>
                <p className="md:-mt-16">...to the Manchester Crows!</p>
                <p className="text-gray-600">
                Here you'll find information on the team,
                our fixtures, and how to get in touch with us. Make sure to follow our
               <a className="no-underline font-medium text-blue-600 hover:underline" href="https://linktr.ee/manchestercrows"> social media accounts </a> for all of our
               updates!
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <a href="https://linktr.ee/manchestercrows" className="text-base no-underline font-semibold leading-7 text-gray-900">
                    View our Linktree <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">

            <div className="md:rounded-3xl shadow-none md:shadow-2xl">
              <div className="md:h-96 w-full rounded-none md:rounded-xl shadow-none md:shadow-xl">

                <img className=" object-cover md:scale-100 scale-125 h-100 md:h-full rounded-none md:rounded-xl" src={TeamPhoto} alt="Manchester Crows Team Photo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Welcome
