import React from 'react';

export default class Igfeed extends React.Component {
  constructor() {
    super();
    this.loadScript = this.loadScript.bind(this)
  }

  loadScript(src, libraryId) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.id = libraryId;
      document.body.appendChild(script);

      script.onload = () => {
        // add a small timeout to give it time to load
        setTimeout(() => {
          resolve(true);
        }, 100);
      };
    });
  }

  componentDidMount() {
    this.loadScript('https://cdn.curator.io/published/2e90c6c3-b736-453d-be91-803ea12d1cf0.js');
  }

  render() {
    return (
      <div className="w-full md:w-2/3 mx-auto ">

      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

      <div data-crt-feed-id="2e90c6c3-b736-453d-be91-803ea12d1cf0"></div>
      </div>
     </div>
    );
  }
}