import * as React from "react"
import About from "../components/about"
import Join from "../components/join"
import Igfeed from "../components/igfeed"
import Footer from "../components/footer"
import Welcome from "../components/welcome"

import Layout from "../components/layout"
import { SEO } from "../components/seo.jsx"
import { Fixtures } from "../components/fixtures"
import SectionHeader from "../components/SectionHeader"


const IndexPage = () => (
  <Layout>
    <Head />
    <Welcome/>
    <SectionHeader title="Fixtures" linkId="fixtures" />
    <Fixtures />
    <SectionHeader title="Socials" linkId="socials"/>
    <Igfeed />
    <SectionHeader title="What Is Flag Football?" linkId="about" titlePunctuation="?"/>
    <About />
    <SectionHeader title="Become a Manchester Crow" linkId="join" />
    <Join />
    <Footer />
  </Layout>
)


export const Head = () => (
  <>
  <SEO />
  </>
  
)

export default IndexPage
