import React from "react"

export default function FixturePagination({
  fixtures,
  setSelectedFixture,
  selectedFixture,
}) {
  return (
    <nav className="flex items-center justify-between pt-10 sm:px-0">
      <div className="-mt-px flex w-0 flex-1"></div>
      <div className="hidden md:-mt-px md:flex">
        {fixtures.map((fixture, i) =>
          selectedFixture === i ? (
            <div
              key={i}
              role="button"
              onClick={() => setSelectedFixture(i)}
              onKeyDown={() => setSelectedFixture(i)}
              className="inline-flex items-center px-4 pt-4 text-sm cursor-pointer font-bold text-crows-red hover:border-gray-300 hover:text-gray-700"
              tabIndex={i}
           >
              Week {i + 1}
            </div>
          ) : (
            <div
              key={i}
              role="button"
              onClick={() => setSelectedFixture(i)}
              onKeyDown={() => setSelectedFixture(i)}
              tabIndex={i}
              className="inline-flex items-center  px-4 pt-4 text-sm font-medium cursor-pointer hover:font-bold text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
              Week {i + 1}
            </div>
          )
        )}
      </div>
      {/* Mobile View */}
      <div className="md:hidden md:-mt-px">
        <p className="text-sm justify-center flex text-gray-500">Game Week</p>
        {fixtures.map((fixture, i) =>
          selectedFixture === i ? (
            <div
              key={i}
              role="button"
              onClick={() => setSelectedFixture(i)}
              onKeyDown={() => setSelectedFixture(i)}
              tabIndex={i}
              className="inline-flex items-center px-4 pt-4 text-sm cursor-pointer font-bold text-crows-red hover:border-gray-300 hover:text-gray-700"
            >
              {i + 1}
            </div>
          ) : (
            <div
              key={i}
              role="button"
              onClick={() => setSelectedFixture(i)}
              onKeyDown={() => setSelectedFixture(i)}
              tabIndex={i}
              className="inline-flex items-center  px-4 pt-4 text-sm font-medium cursor-pointer hover:font-bold text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
              {i + 1}
            </div>
          )
        )}
      </div>

      <div className="-mt-px flex w-0 flex-1 justify-end"></div>
    </nav>
  )
}
