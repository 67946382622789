import React from "react"

const SectionHeader = ({ title, linkId, titlePunctuation}) => {
  return (
    <>
      <div
        id={linkId}
        className="block relative"
        style={{ top: "-100px" }}
        aria-hidden="true"
      >
      </div>
      <div className="my-12 relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300 mx-32" />
        </div>
        <div className="relative flex justify-center">
          <h1 className="bg-gray-50 px-3 text-3xl font-medium text-gray-600">
            {title}<span className="font-sans font-extrabold text-headerpunctuation">{titlePunctuation}</span>
          </h1>

        </div>
      </div>
    </>
  )
}

export default SectionHeader
